.home {
  background: #f2f2f2;
  &__tabs {
    background: transparent linear-gradient(87deg, #366aaf 0%, #16a2ff 100%) 0%
      0% no-repeat padding-box;
    height: 25vh;
    padding: 4rem 8rem 5rem 8rem;
    display: flex;
    justify-content: space-between;
    font-family: "AvenirNextPro", sans-serif;
    @media screen and (max-width: 800px) {
      padding: 1rem;
    }
    & a {
      text-transform: uppercase;
      margin-right: 2rem;
      cursor: pointer;
      color: white;
      text-decoration: none;
    }
    & .nav-link {
      color: white;
      opacity: 0.6;
      &:hover {
        color: white;
        opacity: 1;
      }
      &.active {
        opacity: 1;
        border-bottom: 2px solid white;
        font-weight: bold;
      }
    }
    &__link {
      font-size: 0.8rem;
      &:hover {
        color: white;
      }
    }
  }
  &__card-carousel {
    position: relative;
    top: -50px;
    padding: 0 7rem;
    margin-bottom: -90px;
    @media screen and (max-width: 800px) {
      padding: 0 4rem 4rem 4rem;
    }
    &__spinner {
      top: 30vh;
      left: 50vw;
      position: absolute;
    }
    & .fa-chevron-left {
      position: absolute;
      top: 45%;
      left: -60px;
      color: #bebebe;
    }
    & .fa-chevron-right {
      position: absolute;
      top: 45%;
      right: -50px;
      color: #bebebe;
    }
    & .twoItems {
      width: 50%;
      margin-bottom: 50px;
    }
  }
}

.owl-item > .item {
  width: min-content;
}
