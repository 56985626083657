.footer {
  display: flex;
  height: max-content;
  justify-content: space-between;
  //justify-content: flex-end;
  padding: 1rem 3rem;
  background-color: #f2f2f2;
  grid-row: 3/-1;
  @media screen and (max-width: 800px) {
    padding: 1rem;
  }
  & a {
    text-decoration: none;
    color: #366aaf;
    margin-right: 2rem;
  }
  
}

