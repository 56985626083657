.appCard {
  background-color: white;
  min-width: 18rem;
  width: 16vw;
  height: 24rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 10px 34px #201f2408;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 18.5rem;
  }

  &__disabled {
    filter: grayscale(1);
  }

  &__img,
  &__disabled__img {
    padding: 2rem;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    &__dec1 {
      width: 15px !important;
      height: 15px;
      align-self: flex-end;
    }
    &__logo {
      width: 100px !important;
      height: 100px;
      align-self: center;
    }
    &__dec2 {
      width: 15px !important;
      height: 15px;
      align-self: flex-start;
    }
  }
  &__heading,
  &__disabled__heading {
    text-transform: uppercase;
    font-weight: bold;
    font-family: "AvenirNextPro", sans-serif;
    align-items: flex-start;
    margin: 2rem;
    //color:black;
    height: 15%;
    img {
      width: 140px !important;
    }
    align-self: center;
  }
  &__title,
  &__disabled__title {
    flex: 1;
    &__text {
      border-left: 4px solid #366aaf;
      text-decoration: none;
      padding: 0 2rem;
      // text-transform: uppercase;
      font-family: "AvenirNextPro", sans-serif;
      //font-weight: bold;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: gray;
    }
  }
  &__footer,
  &__disabled__footer {
    font-size: 1rem;
    font-family: "AvenirNextPro", sans-serif;
    width: 100%;
    height: 3.5rem;
    &__launch {
      padding: 1rem 2rem;
      background-image: linear-gradient(to right, #3270b9, #269cef);
      color: #fff;
      display: flex;
      justify-content: center;
    }
    &__disabled {
      padding: 1rem 2rem;
      background-image: linear-gradient(to right, #ff9900, #ffcc35);
      color: black;
      display: flex;
      justify-content: center;
    }
  }
  &__footer_m,
  &__disabled__footer_m {
    font-size: 0.9rem;
    font-family: "AvenirNextPro", sans-serif;
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__launch {
      padding: 1rem 1.5rem;
      background-image: linear-gradient(to right, #3270b9, #269cef);
      color: #fff;
    }
    &__disabled {
      padding: 1rem 2rem;
      background-image: linear-gradient(to right, #ff9900, #ffcc35);
      color: black;
    }
  }
}

.card-list {
  display: grid;
  background-color: #f2f2f2;
  grid-template-columns: repeat(auto-fit, 270px);
  grid-row-gap: 2rem;
  grid-column-gap: 3.5rem;
  padding: 2rem 0;
  justify-content: center;
}
