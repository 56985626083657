.view-all {
  background: #f2f2f2;
  padding: 1.5rem 1.5rem;
  &__tabs {
    display: flex;
    justify-content: space-between;
    & a {
      text-transform: uppercase;
      margin-right: 2rem;
      cursor: pointer;
      text-decoration: none;
    }
  }
  & .nav-link {
    color: black;
    opacity: 0.6;
    font-family: "AvenirNextPro", sans-serif;
    text-transform: uppercase;
    &:hover {
      color: black;
      opacity: 1;
    }
    &.active {
      opacity: 1;
      border-bottom: 2px solid black;
      font-weight: bold;
    }
  }
}
a {
  text-decoration: none !important;
}

.main-container {
  padding: 1rem;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
}
