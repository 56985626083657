// COLOR VARIABLES
$color-primary: #366aaf;
$color-secondary: #3172ba;

$color-light-1: #fff;
$color-light-1: #f2f2f2;

@font-face {
  font-family: "AvenirNextBold";
  src: local("AvenirNextBold"),
    url("../fonts/Avenir-Next-LT-Pro-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "AvenirNextPro";
  src: local("AvenirNextPro"),
    url("../fonts/Avenir-Next-LT-Pro-Demi.otf") format("opentype");
  font-weight: bold;
}

html {
  font-family: "AvenirNextPro", sans-serif !important;
}

.main-content {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
  display: grid;
  grid-template-rows:
    minmax(min-content, 5vh) 1fr
    minmax(min-content, 4vh);
  grid-template-columns: 100vw;
}

.btn-primary {
  background-color: #fff;
  border-radius: 5px;
  color: #366aaf;
  text-transform: uppercase;
  border: none;
  padding: 0.5rem 3rem;
  margin: 0 2rem;
  font-family: "AvenirNextPro";
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: #366aaf;
  }
}
a {
  text-decoration: none;
}

.app-text-primary {
  font-family: "AvenirNextPro";
  font-weight: 600;
}

.app-text-primary-bold {
  font-family: "AvenirNextBold";
}
