.nav-bar {
  background: transparent linear-gradient(87deg, #366aaf 0%, #16a2ff 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 80px;
  color: white;
  margin: 0;
  font-family: "AvenirNextPro", "Regular";

  &__container {
    margin: 0 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // @media screen and (max-width: 800px) {
    //   display: block;
    // }
  }

  &__logo {
    display: flex;
    align-items: center;

    &__img {
      margin-right: 1rem;

      & img {
        height: 50px;
        width: 50px;
      }
    }

    &__title {
      font-family: "Times New Roman", "Regular";
      font-size: 1.1rem;
      line-height: 20px;
    }
  }

  &__popover {
    display: flex;
    align-items: center;
    font-family: "AvenirNextPro";
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.2);
    padding: 0.5rem 1rem 0;
    border-radius: 5px;
    height: 50px;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent hsla(0, 0%, 100%, 0.2);
      top: 40%;
      right: 360px;
      // @media screen and (max-width: 800px) {
      //   display: block;
      // }
    }
  }

  &__menu {
    display: flex;
    align-items: center;

    &__search {
      height: 50px;
      width: 400px;
      position: absolute;
      z-index: 3;
      right: 3rem;
      border: none;
      border-radius: 5px;
      visibility: hidden;
      padding: 0 10px;

      &.show {
        visibility: visible;
      }
    }

    // &__logout-btn {
    //   font-family: "AvenirNextPro";
    //   cursor: pointer;
    //   color: #fff;
    //   font-size: 1rem;
    //   background-color: hsla(0, 0%, 100%, 0.2);
    //   padding: 0 0rem 0 3rem;
    //   border-radius: 5px;
    //   margin: 0 2rem;
    //   height: 50px;
    //   overflow: hidden;
    //   display: flex;
    //   align-items: center;
    //   text-transform: uppercase;
    // }
    // &__login-btn {
    //   font-family: "AvenirNextPro";
    //   cursor: pointer;
    //   color: #3172ba;
    //   text-transform: uppercase;
    //   font-size: 1rem;
    //   background-color: white;
    //   padding: 0 0rem 0 3rem;
    //   border-radius: 5px;
    //   margin: 0 2rem;
    //   height: 50px;
    //   overflow: hidden;
    //   display: flex;
    //   align-items: center;
    // }
    &__icon {
      height: 80px;
      width: 80px;
      cursor: pointer;
      margin-top: 6px;
      left: -1rem;
      position: relative;
    }

    &__search__icon {
      cursor: pointer;
      z-index: 99;
      color: white;
      font-size: 15px;
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 10px;
      border-radius: 50%;

      &.show {
        color: black;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__title {
        font-family: "AvenirNextPro";
        font-size: 1rem;
        text-align: left;
        line-height: 0.5;
        margin-top: 5px;
      }

      &__desc {
        font-family: "AvenirNextPro";
        font-size: 0.8rem;
        text-align: left;

        & a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    &__dropdown {
      margin-right: 2rem;

      &__toggle {
        display: flex;

        &__img {
          height: 50px;
          width: 50px;
          border: 3px solid transparent;
          border-radius: 50%;
          margin-right: 10px;
        }

        &__sub-title {
          font-size: 13px;
          opacity: 0.6;
        }
      }

      & .nav-link {
        color: white;
        margin: 0;
        font-family: "AvenirNextPro", sans-serif;

        &:hover {
          color: white;
        }

        &:focus {
          color: black;
        }
      }

      .dropdown-menu[data-bs-popper] {
        width: 100%;
        margin-top: 0;
        padding: 1rem;
      }

      .dropdown-toggle::after {
        content: none;
      }

      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f078";
        border-top: none;
        margin-left: 10px;
      }
    }

    &__dropdown.show {
      background-color: white;
      color: black;

      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f105";
        border-top: none;
        margin-left: 10px;
      }
    }

    &__dropdown2 {
      margin-right: 2rem;

      &__toggle {
        display: flex;

        &__img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          margin-right: 10px;
          background-color: hsla(0, 0%, 100%, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 1.5rem;
          letter-spacing: -2px;
        }

        &__sub-title {
          font-size: 13px;
          opacity: 0.6;
        }
      }

      & .nav-link {
        color: white;
        font-family: "AvenirNextPro", "Regular";

        &:hover,
        &:focus {
          color: white;
        }
      }

      .dropdown-menu[data-bs-popper] {
        width: 100%;
        margin-top: 0;
        padding: 1rem;
      }

      .dropdown-toggle::after {
        content: none;
      }

      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f078";
        border-top: none;
        margin-left: 10px;
      }
    }
  }
}

// .nav {
//   background: transparent linear-gradient(87deg, #366aaf 0%, #16a2ff 100%) 0% 0%
//     no-repeat padding-box !important;
// }
// .nav-bar__menu__logout-btn {
//   font-family: "AvenirNextPro";
//   cursor: pointer;
//   color: #fff;
//   //font-size: 1rem;
//   font-size: smaller;
//   background-color: hsla(0, 0%, 100%, 0.2);
//   // padding: 0 0rem 0 3rem;
//   //padding: 9px;
//  // border-radius: 100px;
//   margin: 0 2rem;

//   overflow: hidden;
//   display: flex;

//   text-transform: uppercase;
// }